import { Button, Paper, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import * as InitmapConfig from '../../../config/config';
import * as importTiffFileAction from "../../../../../redux/store/import-shape-tiff-file/import-tiff-file.store";
import * as checkExistLayerName from "../../../../../redux/store/import-shape-tiff-file/check-exist-layer-name.store";
import ShowNotification from "../../../../../components/react-notifications/react-notifications";
import * as viVN from "../../../../../language/vi-VN.json";
import { NotificationMessageType } from '../../../../../utils/configuration';

export default function SettingLayerTypeView(props) {
    const {
        isImportShapeFile,
        isImportTifFile,
        setValueShapeFileStep_2,
        valueShapeFileStep_2,
        isLayerRela,
        layerId,
        showLoading
    } = props;

    const [layerName, setLayerName] = useState(props.layerSetting.layerName);
    const [layerZIndex, setLayerZIndex] = useState(props.layerSetting.zIndex)
    const [layerMinzoom, setLayerMinzoom] = useState(props.layerSetting.minZoom);
    const [layerMaxzoom, setLayerMaxzoom] = useState(props.layerSetting.maxZoom);
    const [viewDefault, setViewDefault] = useState(props.layerSetting.defaultTurnOn);
    const [year, setYear] = useState();
    const [contentChange, setContentChange] = useState();
    const [geoTiff, setGeoTiff] = useState('');
    const [isDisableGeoTiff, setIsDisableGeoTiff] = useState(true);
    const [valueTiffFile_2, setValueTiffFile2] = useState(null);

    const handleImportTiffFile = async () => {
        showLoading(true);
        const passValidate = await validateFileInput();
        if(!passValidate) {
            showLoading(false);
            return;
        }
        ImportTiffFile(valueTiffFile_2)
        .then((res) => {
          if (res && res.content) {
            ShowNotification(
              viVN.Success.ImportTiffFileSuccess,
              NotificationMessageType.Success
            );
          }
          showLoading(false);
        })
        .catch((err) => {
          showLoading(false);
          console.log("err", err);
        });
    }

    const ImportTiffFile = (valueTiffFile_2) => {
        return new Promise((resolve, reject) => {
          importTiffFileAction.ImportTiffFile(valueTiffFile_2).then(
            (res) => {
              resolve(res);
            },
            (err) => {
              reject(err);
              err &&
                err.errorType &&
                ShowNotification(
                  viVN.Errors[err.errorType],
                  NotificationMessageType.Error
                );
            }
          );
        });
    };

    const validateFileInput = async () => {
        let validateReuslt = true;
        if (!valueTiffFile_2 || !valueTiffFile_2.file) {
            ShowNotification(
                viVN.Errors.FileEmpty,
                NotificationMessageType.Error
            );
            validateReuslt = false;
            return validateReuslt;
        }

        if (valueTiffFile_2.file && !valueTiffFile_2.TiffName) {
            ShowNotification(
                viVN.Errors.NameIsEmpty,
                NotificationMessageType.Error
            );
            validateReuslt = false;
            return validateReuslt;
        }

        const checkExistResult = await callCheckExistLayerName(valueTiffFile_2.TiffName);
        if( checkExistResult ) {
            // true => trùng tên => ko cho đi tiếp
            console.log('true => trùng tên => ko cho đi tiếp');
            ShowNotification(
                viVN.Errors.NameExisted,
                NotificationMessageType.Error
            );
            validateReuslt = false;
            return validateReuslt;
        }
        
        return validateReuslt;
    }

    const callCheckExistLayerName = (tiffName) => {
        return new Promise((resolve, reject) => {
            checkExistLayerName.CheckExistLayerName(tiffName).then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        }).then((res) => {
            if (res && res.content && res.content.status == false) {
                console.log('return false;');
                return false;
            }
            console.log('return true 1;');
            return true;
        })
        .catch((err) => {
            err &&
                err.errorType &&
                ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
            console.log('return true 2;');
            return true;
        });
    };

    useEffect(()=>{
        if(!valueTiffFile_2) return;

        if(valueTiffFile_2.file) {
            setIsDisableGeoTiff(false);
        }
    },[valueTiffFile_2])
    // end handling import tiff

    const handleUpdateData = () => {
        if(isLayerRela) {
            props.setLayerSetting(
                new InitmapConfig.CreateLayerSettingObject(
                    layerName, 
                    layerMinzoom, 
                    layerMaxzoom, 
                    layerZIndex, 
                    viewDefault, geoTiff,
                    year,
                    contentChange,
                    layerId,
                )
            );
        } else {
            props.setLayerSetting(
                new InitmapConfig.CreateLayerSettingObject(
                    layerName, 
                    layerMinzoom, 
                    layerMaxzoom, 
                    layerZIndex, 
                    viewDefault,
                )
            );
        }
    }

    useEffect(() => {
        handleUpdateData()
    }, [layerName, layerZIndex, layerMinzoom, layerMaxzoom, viewDefault, contentChange, year, geoTiff])
    useEffect(() => {
        if (isLayerRela) {
            console.log('okokokokok')
        }
    }, [isLayerRela])
    return (
        <div className="container-fluid">
            {
                isImportShapeFile
                    ? (
                        <div className="row mt-3">
                            <div className="col-6 mx-auto">
                                <Paper className="p-3" elevation={3}>
                                    <div className="form-group">
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            label="Tên thư mục cha"
                                            defaultValue={valueShapeFileStep_2.RootFolderName}
                                            disabled={true}
                                            onChange={(event) => setValueShapeFileStep_2({ ...valueShapeFileStep_2, RootFolderName: event.target.value })}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            label="Tên Shape file"
                                            disabled={true}
                                            defaultValue={valueShapeFileStep_2.ShpFileName}
                                            onChange={(event) => setValueShapeFileStep_2({ ...valueShapeFileStep_2, ShpFileName: event.target.value })}
                                        />
                                    </div>

                                    <div>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            label="Tên bảng"
                                            disabled={true}
                                            defaultValue={valueShapeFileStep_2.TableName}
                                            onChange={(event) => setValueShapeFileStep_2({ ...valueShapeFileStep_2, TableName: event.target.value })}
                                        />
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    ) : (
                        <div className="row mt-3">
                            <div className="col-12">
                                <Paper className="p-3" elevation={3}>
                                    <div className="row">
                                        <div className="col-6 mx-auto">
                                                <div className="col-12 mx-auto">
                                                <div className="form-group">
                                                    <div className='row'>
                                                            <label className='col-6' htmlFor="inputLayerName">Tên layer <span style={{ color: 'red' }}>*</span></label>
                                                            <div class="form-check col-6">
                                                                <input type="checkbox" checked={viewDefault} onClick={() => setViewDefault(!viewDefault)} class="form-check-input" id="checkboxDefaultView" />
                                                                <label class="form-check-label" htmlFor="checkboxDefaultView">Hiển thị mặc định</label>
                                                            </div>
                                                        </div>
                                                    <input type="text" className="form-control" value={layerName} onChange={(event) => setLayerName(event.target.value)} id="inputLayerName" placeholder="Nhập tên layer" />
                                                </div>
                                                {isLayerRela && (
                                                <div>
                                                    <div className="form-group mt-3">
                                                        <label htmlFor="inputLayerName">Năm điều chỉnh <span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={year}
                                                            onChange={(event) => setYear(event.target.value)}
                                                            id="inputLayerName"
                                                            placeholder="Nhập năm điều chỉnh"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="inputPlanningName">Nội dung điều chỉnh <span style={{ color: 'red' }}>*</span></label>
                                                        <textarea
                                                            rows="4" 
                                                            cols="50"
                                                            type="text"
                                                            className="form-control"
                                                            value={contentChange}
                                                            onChange={(event) => setContentChange(event.target.value)}
                                                            id="inputPlanningName"
                                                            placeholder="Nhập nội dung"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div className="form-group">
                                                    <label htmlFor="inputLayerName">File Tiff (Optional)</label>
                                                        <TextField
                                                            fullWidth
                                                            value={geoTiff}
                                                            onChange={(event) => {
                                                                setValueTiffFile2({...valueTiffFile_2, TiffName: event.target.value})
                                                                setGeoTiff(event.target.value);
                                                            }}
                                                            variant="outlined"
                                                            size="small"
                                                            label="Tên tiff"
                                                            error={props.error}
                                                            disabled={isDisableGeoTiff}
                                                        />
                                                        {props.error && <span className="error">{props.error}</span>}
                                                    </div>
                                                    <div className='row'>
                                                        <div className="form-group col-6">
                                                            <input
                                                                type="file"
                                                                onChange={(event) => {
                                                                    let arrFiles = Array.prototype.slice.call(event.target.files);
                                                                    arrFiles.length > 0 &&
                                                                        setValueTiffFile2({...valueTiffFile_2, file: arrFiles[0],TiffName: ''});
                                                                        setGeoTiff('');
                                                                }}
                                                                accept=".tif, .tiff"
                                                            />
                                                    </div>
                                                        <div className="form-group text-right col-6">
                                                            <Button variant="contained" color="primary" onClick={handleImportTiffFile}>
                                                                Publish
                                                            </Button>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                            
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label htmlFor="inputLayerName">Z-index:</label>
                                                <input type="number" className="form-control" min={1} value={layerZIndex} onChange={(event) => setLayerZIndex(event.target.value)} id="inputLayerName" placeholder="Mức độ ưu tiên hiển thị của layer" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputLayerName">Min Zoom:</label>
                                                <input type="number" className="form-control" value={layerMinzoom} min={0} onChange={(event) => setLayerMinzoom(event.target.value)} id="inputLayerName" placeholder="Mức độ thu nhỏ nhất" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputLayerName">Max Zoom:</label>
                                                <input type="number" className="form-control" value={layerMaxzoom} max={20} onChange={(event) => setLayerMaxzoom(event.target.value)} id="inputLayerName" placeholder="Nhập tên layer" />
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}