import React, { useEffect, useState } from "react";
import * as MaterialUi from '@material-ui/core';
import { IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from "../../../../../../core/app.store";

function RedirectToTiffModal (props) {

    const handleClose = () => {
        props.handleClose();
    }


    return (
        <>
            <MaterialUi.Dialog
                maxWidth="lg"
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth={true}
                open={props.open}
                onClose={handleClose}
            >
                <MaterialUi.DialogTitle id="alert-dialog-title">
                    <Typography variant="h6">Thông báo</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            handleClose();
                        }}
                        style={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            color: "#9e9e9e",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </MaterialUi.DialogTitle>

                <MaterialUi.DialogContent dividers>
                    <div id="alert-dialog-description" className="mb-0" style={{color:'black'}}>
                        Bạn nên thêm lớp tiff sau khi chọn "no_style"
                    </div>
                </MaterialUi.DialogContent>
                <MaterialUi.DialogActions className="justify-content-center mt-2 mb-2">
                    <MaterialUi.Button variant="contained" onClick={handleClose} color="primary" className="ml-2">
                        Tiếp tục
                    </MaterialUi.Button>
                </MaterialUi.DialogActions>
            </MaterialUi.Dialog>
        </>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
  showLoading: appActions.ShowLoading,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RedirectToTiffModal)