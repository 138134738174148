import React, { useEffect, useState } from "react";
import "date-fns";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import { NotificationMessageType, APIUrlDefault, MaxSizeImageUpload, } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as newsAction from "../../../redux/store/news/news.store";

const SearchNews = (props) => {
  const {
    searchData: { title, status, categoryId },
    onChangeTitle,
    onChangeStatus,
    onChangeCategory,
    refresh,
    onSubmit,
  } = props;

  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  const [isOpenMobileSearch, setIsOpenMobileSearch] = useState(false);
  const [categoriesModel, setCategoriesModel] = useState([]);

  useEffect(() => {
    getLookupCategories();
  }, [])

  const getLookupCategories = () => {
    newsAction.GetLookupCategories().then(res => {
      if (res && res.content && res.content.length > 0) {
        setCategoriesModel(res.content);
      } else {
        setCategoriesModel([]);
      }
    })
      .catch((err) => {
        err.errorMessage && ShowNotification(
          err.errorMessage,
          NotificationMessageType.Error
        )
      });
  }

  return (
    <div class="wrap__content-page qlhs-form">
      {
        isTabletOrMobile &&
        <div class={`form-group col-12 col-lg-6 ${isTabletOrMobile ? 'd-flex flex-column' : ''} `}>
          {
            !isOpenMobileSearch ?
              (<button class="btn btn-ct btn-primary-ct btn-inline" type="button"
                onClick={() => { setIsOpenMobileSearch(!isOpenMobileSearch); }}
              >
                Tìm kiếm
              </button>) :
              (<button class="btn btn-ct btn-danger-ct btn-inline" type="button"
                onClick={() => { setIsOpenMobileSearch(!isOpenMobileSearch); }}
              >
                Đóng
              </button>)
          }
        </div>
      }

      {
        (isDesktopOrLaptop || (isOpenMobileSearch && isTabletOrMobile)) &&
        <form onSubmit={handleSubmit(onSubmit)}>
          <div class="form-row">
            <div class="form-group col-12 col-lg-3">
              <input
                id="input-search"
                type="text"
                value={title}
                name="title"
                onChange={onChangeTitle}
                class="form-control"
                placeholder="Tiêu đề"
                inputRef={register}
              />
            </div>
            {categoriesModel.length > 0 && (
              <div class="form-group col-12 col-lg-2">
                <select
                  name="categoryId"
                  value={categoryId}
                  class="custom-select"
                  onChange={onChangeCategory}
                  inputRef={register}
                  placeholder="Loại tin tức"
                >
                  <option value={""}>Tất cả loại tin</option>
                  {categoriesModel.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
            )}
            <div class="form-group col-12 col-lg-2">
              <select
                name="status"
                value={status}
                class="custom-select"
                onChange={onChangeStatus}
                inputRef={register}
                placeholder="Trạng thái"
              >
                <option value={2}>----------------</option>
                <option value={1}>Kích hoạt</option>
                <option value={0}>Không kích hoạt</option>
              </select>
            </div>
            <div class={`form-group col-12 col-lg-3 ${isTabletOrMobile ? 'd-flex flex-column' : ''}`} style={{ display: "flex" }}>
              <button class="btn btn-ct btn-default-ct" onClick={refresh}>
                Xóa
              </button>
              <button class="btn btn-ct btn-primary-ct" type="submit">
                Tìm kiếm
              </button>
            </div>
          </div>
        </form>
      }

    </div>
  );
};

export default SearchNews;
