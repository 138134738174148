import React, { useEffect, useState } from "react";
import * as MaterialUi from '@material-ui/core';
import MDEditor, { commands } from '@uiw/react-md-editor';
import ConfirmModalView from "../../../../../../components/confirm-modal/confirm-modal";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import ShowNotification from "../../../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from '../../../../../../utils/configuration';
import * as viVN from "../../../../../../language/vi-VN.json";


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from "../../../../../../core/app.store";
import * as apiLayerStore from "../../../../../../redux/store/layer-style/layer-style.store";

function AddStyleModalView(props) {
    const { listStyle, showLoading } = props;

    const successMessageContent = "Không có lỗi nào.";
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [canApply, setCanApply] = useState(false);

    const [newStyleName, setNewStyleName] = useState("");
    const [copyStyleName, setCopyStyleName] = useState("");
    const [styleXml, setStyleXml] = useState("");

    const [openConfirmOverwriteModal, setOpenConfirmOverwriteModal] = useState(false);
    const [openConfirmDiscardChanges, setOpenConfirmDiscardChanges] = useState(false);

    const [currentFile, setCurrentFile] = useState(null);
    
    useEffect(() => {
        if(!errorMessage && successMessage) {
            //trường hợp validate thành công
            setCanApply(true);
        } else {
            setCanApply(false);
        }
    },[successMessage,errorMessage])

    //call api
    const callGetStyleXmlByStyleName = async (styleName) => {
        showLoading(true);
        setErrorMessage("");
        setSuccessMessage("");
        return await new Promise((resolve, reject) => {
            apiLayerStore.GetStyleXmlByStyleName(styleName).then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        }).then((res) => {
            if (res && res.content && res.content.data) {
                setStyleXml(res.content.data);
            }
            showLoading(false);
        })
        .catch((err) => {
        err &&
            err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        showLoading(false);
        });
    };
    
    const callValidateStyle = async () => {
        showLoading(true);
        return await new Promise((resolve, reject) => {
            apiLayerStore.ValidateStyle(styleXml).then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        }).then((res) => {
            if (res && res.content) {
                if (res.content.status) {
                setErrorMessage("");
                setSuccessMessage(successMessageContent);
                }
            }
            showLoading(false);
        })
        .catch((err) => {
            if(err) {
                setErrorMessage(err.errorMessage);
                setSuccessMessage("");
            }
            err &&
                err.errorType &&
                ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
            showLoading(false);
        });
    };

    const callCreateStyleWithXmlStr = async () => {
        const tempStyleXml = styleXml;
        setStyleXml("");
        return await new Promise((resolve, reject) => {
            apiLayerStore.CreateStyleWithXmlStr(newStyleName,tempStyleXml).then(
                (res) => {
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        }).then((res) => {
            ShowNotification(
                viVN.Success.AddStyleSuccess,
                NotificationMessageType.Success
            );
            showLoading(false);
            props.callGetListStyle();
            handleClose();
        })
        .catch((err) => {
            err &&
                err.errorType &&
                ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
                );
            showLoading(false);
        });
    };

    //modal behavior
    const handleAccept = () => {
        handleAddStyle();
    }

    const handleClose = () => {
        props.handleClose();
        setNewStyleName("");
        setCopyStyleName("");
        setStyleXml("");
        setErrorMessage("");
        setSuccessMessage("");
    }

    const handleAddStyle = () => {
        showLoading(true);
        const validateResult = validateCreateStyleInput();
        if(!validateResult) {
            ShowNotification(
            viVN.Errors.NameIsEmpty,
            NotificationMessageType.Error
            );
            showLoading(false);
            return;
        }
        callCreateStyleWithXmlStr();
    }

    const validateCreateStyleInput = ()=> {
        if(!newStyleName) return false;
        return true;
    }

    const readFileContent = () => {
        if(!currentFile) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target.result;
            setStyleXml(content);
            showLoading(false);
        };
        reader.readAsText(currentFile);
        showLoading(true);

        setCurrentFile(null);
        const fileInput = document.getElementById('uploadStyleXml');
            if (fileInput) {
            fileInput.value = null;
        }
    }

    const actionConfirmOverwriteModal1 = () => {
        if(!copyStyleName) {
            setStyleXml("");
            return;
        }
        callGetStyleXmlByStyleName(copyStyleName);
    }

    const actionConfirmOverwriteModal2 = () => {
        readFileContent();
    }

    return (
        <>
            <MaterialUi.Dialog
                maxWidth="lg"
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth={true}
                open={props.open}
                onClose={handleClose}
            >
                <MaterialUi.DialogTitle id="alert-dialog-title">
                    <Typography variant="h6">Thêm mới style</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            if(styleXml) {
                                setOpenConfirmDiscardChanges(true);
                                return;   
                            }
                            handleClose();
                        }}
                        style={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            color: "#9e9e9e",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </MaterialUi.DialogTitle>

                <MaterialUi.DialogContent dividers>
                    <div id="alert-dialog-description" className="mb-0" style={{color:'black'}}>
                        <div className="form-group">
                            <label for="newStyleName" className="h6">Tên style</label>
                            <input
                            type="text"
                            className="form-control"
                            value={newStyleName}
                            id="newStyleName"
                            placeholder="Nhập tên style"
                            onChange={event => setNewStyleName(event.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label for="copyStyleXmlFrom" className="h6">Copy nội dung style có sẵn</label>
                            <Autocomplete
                                id="copyStyleXmlFrom"
                                blurOnSelect={true}
                                inputValue={copyStyleName}
                                onInputChange={(event, option) => {
                                    setCopyStyleName(option ? (typeof option === "string" ? option : option.name) : "");
                                }}
                                onChange={(event, option) => {
                                    setCopyStyleName(option ? (typeof option === "string" ? option : option.name) : "");
                                    if(styleXml) {
                                        setOpenConfirmOverwriteModal(1);
                                    }else {
                                    if(!option || !option.name) return;
                                        callGetStyleXmlByStyleName(typeof option === "string" ? option : option.name);
                                    }
                                }}
                                options={listStyle}
                                getOptionLabel={(option) =>
                                    typeof option === "string" ? option : option.name
                                }
                                renderInput={(params) => <TextField {...params} label="Copy từ style có sẵn" variant="outlined" />}
                            />
                        </div>

                        <div className="form-group">
                            <label for="uploadStyleXml" style={{display: 'block'}} className="h6">Upload file style</label>
                            <input
                                id="uploadStyleXml"
                                type="file"
                                onChange={(event) => {
                                    let arrFiles = Array.prototype.slice.call(event.target.files);
                                    setCurrentFile(arrFiles[0]);
                                }}
                                accept=".xml, .sld, .txt"
                            />
                            <button className="btn text-primary" style={{textDecoration:'underline'}}
                                onClick={() => {
                                    if(styleXml) {
                                        setOpenConfirmOverwriteModal(2);
                                    }else {
                                        readFileContent();
                                    }
                                }}
                            >Upload</button>
                        </div>

                        <div className="form-group">
                            <label className="h6">File Style</label>
                            <MDEditor
                                value={styleXml}
                                onChange={(val) => {
                                    setStyleXml(val);
                                }}
                                commands={[]}
                                extraCommands={[commands.fullscreen]}
                                preview="edit"
                            />
                        </div>
                        
                        <div className="">
                            {
                                errorMessage && 
                                <div style={{fontSize: '16px', color: 'red'}}>
                                {errorMessage}
                                </div>
                            }
                            {
                                successMessage && 
                                <div style={{fontSize: '16px', color: 'green'}}>
                                {successMessage}
                                </div>
                            }
                        </div>
                    </div>
                </MaterialUi.DialogContent>
                <MaterialUi.DialogActions className="justify-content-center mt-2 mb-2">
                    <MaterialUi.Button variant="contained" 
                        onClick={() => {
                            if(styleXml) {
                                setOpenConfirmDiscardChanges(true);
                                return;   
                            }
                            handleClose();
                        }}
                    >
                        Hủy
                    </MaterialUi.Button>
                    <MaterialUi.Button variant="contained" onClick={callValidateStyle} color="primary">
                        Validate
                    </MaterialUi.Button>
                    <MaterialUi.Button variant="contained" onClick={handleAccept} color="primary" className="ml-2" disabled={!canApply}>
                        Tiếp tục
                    </MaterialUi.Button>
                </MaterialUi.DialogActions>
            </MaterialUi.Dialog>

            <ConfirmModalView
                open={openConfirmOverwriteModal}
                handleClose={() => setOpenConfirmOverwriteModal(false)}
                title={"Bạn có chắc muốn ghi đè lên nội dung có sẵn?"}
                handleAccept={() => {
                    if (openConfirmOverwriteModal == 1) {
                        actionConfirmOverwriteModal1();
                    } else if (openConfirmOverwriteModal == 2) {
                        actionConfirmOverwriteModal2();
                    } else {
                        return;
                    }
                }}
            />

            <ConfirmModalView
                open={openConfirmDiscardChanges}
                handleClose={() => setOpenConfirmDiscardChanges(false)}
                title={"Bạn chưa lưu style vừa tạo, bạn có chắc muốn tắt không?"}
                handleAccept={handleClose}
            />
        </>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
  showLoading: appActions.ShowLoading,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AddStyleModalView)