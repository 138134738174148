import React, { useState, useEffect } from 'react';
import * as MaterialUi from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as InitMapStore from '../../../../../redux/store/init-map/init-map.store';
import * as InitmapConfig from '../../../config/config';
import { NotificationMessageType, WmsBaseLink } from '../../../../../utils/configuration';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMediaQuery } from 'react-responsive';
import Paper from "@material-ui/core/Paper";
import * as appActions from "../../../../../core/app.store";
import Button from "@material-ui/core/Button";
import TabPanel from '../child/TabPanel-edit';
import RedirectToStyleModal from './redirect-to-style-modal/redirect-to-style-modal';

let GlobalIdSetTimeOut = null

function a11yPropsEdit(index) {
    return {
        id: `edit-verison-${index}`,
        'aria-controls': `edit-verison-tabpanel-${index}`,
    };
}

function ChangeLayerDataSource(props) {
    const { showLoading } = props;
    const [valueTab, setValueTab] = useState(0);
    const [wms_external, setWms_external] = useState(props.data.wms_external || false)
    const [wms, setWms] = useState(props.data.wms)
    const [table, setTable] = useState('')
    const [style, setStyle] = useState('');
    const [wmsParameters, setWmsParameters] = useState('');
    const [geoTiff, setGeoTiff] = useState(props.data.geoTiff);
    const [isDisableGeoTiff, setIsDisableGeoTiff] = useState(true);
    const [geoTiffOld, setGeoTiffOld] = useState(props.data.geoTiff);
    const [valueTiffFile_2, setValueTiffFile2] = useState(null);

    const [openRedirectToStyle, setOpenRedirectToStyle] = useState(false);

    useEffect(() => {
        setTable(props.data.table)
        props.GetListDataSource()
        if (props.dataSource.wms_external) setWms_external(true);
    }, [])

    useEffect(() => {
        if(props.isJumpFromStyle) {
            props.setIsJumpFromStyle(false);
            setValueTab(1);
        }
    }, [props.isJumpFromStyle])

    useEffect(() => {
        if (table || wms) {
            if (GlobalIdSetTimeOut) {
                clearTimeout(GlobalIdSetTimeOut)
            }
            createTaskCreateWmsLink()
        }
    }, [table, wms])

    const createTaskCreateWmsLink = () => {
        GlobalIdSetTimeOut = setTimeout(() => {
            handleCreateWms()
            GlobalIdSetTimeOut = null
        }, 750)
    }

    const handleCreateWms = () => {
        let tableSelected = null;
        let wmsSelected = null;
        let colsSelected = null;
        if (wms_external) {
            tableSelected = InitmapConfig.getTableNameFormUrl(wms);
            wmsSelected = wms;
        } else {
            tableSelected = table;
            wmsSelected = WmsBaseLink + table;
        }
        props.listDataSource.map(dataSource => {
            if (dataSource.tableName === tableSelected) {
                colsSelected = dataSource.cols;
                return;
            }
        })
        if (colsSelected) {
            props.setData({ ...props.data, wms_external: wms_external, wms: wmsSelected, table: tableSelected, display: InitmapConfig.CreateDefaultDisplayStandardObjec(colsSelected), filter: { in: [], out: [], order: '' } })
        }
    }

     //media query
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

    useEffect(()=>{
        props.setData({ ...props.data, geoTiff:geoTiff, geoTiffOld: geoTiffOld});
    },[geoTiff])

    useEffect(()=>{
        props.setValueTiffFile(valueTiffFile_2);
        if(!valueTiffFile_2) {
            setGeoTiff(geoTiffOld);
            setIsDisableGeoTiff(true);
            return;
        }
        if(valueTiffFile_2.file) {
            setIsDisableGeoTiff(false);
        }
    },[valueTiffFile_2])

    const clearTiffFileInfo = () => {
        setGeoTiff("");
        setValueTiffFile2(null);
        setOpenRedirectToStyle(true);
        
        const tiffFileInput = document.getElementById("tiff-file-input");
        tiffFileInput.value = null;
    }

    return (
        <div className={`change-layer-data-source ${isDesktopOrLaptop ? 'container-fluid' : ''}`}>
            <MaterialUi.AppBar position="static">
                <MaterialUi.Tabs value={valueTab} onChange={(event, val) => setValueTab(val)} aria-label="hjhj" centered>
                    <MaterialUi.Tab label="Shape File" {...a11yPropsEdit(0)} />
                    <MaterialUi.Tab label="Tiff File" {...a11yPropsEdit(1)} /> 
                </MaterialUi.Tabs>
            </MaterialUi.AppBar>
            <TabPanel value={valueTab} index={0}>
                <div className="row p-0 m-0">
                    <div className="col-lg-6 p-0 m-0">
                        <label>
                            <MaterialUi.Radio
                                color="primary"
                                checked={!wms_external}
                                onChange={() => setWms_external(false)}
                                name="radio-button-demo"
                                id=""
                            /> Wms trong hệ thống
                        </label>
                    </div>
                    <div className="col-lg-6 p-0 m-0">
                        <label>
                            <MaterialUi.Radio
                                color="primary"
                                checked={wms_external}
                                onChange={() => setWms_external(true)}
                                name="radio-button-demo"
                            />Wms ngoài hệ thống
                        </label>
                    </div>
                    <div className="col-12" hidden={!wms_external}>
                        <div className="form-group">
                            <label htmlFor="inputOutSystemWms">Link wms (*):</label>
                            <input type="text" className="form-control" id="inputOutSystemWms" value={wms} onChange={(event) => setWms(event.target.value)} placeholder="Nhập địa chỉ wms" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputWmsParameters">Sử dụng tham số:</label>
                            <input type="text" className="form-control" id="inputWmsParameters" value={wmsParameters} onChange={event => setWmsParameters(event.target.value)} placeholder="Nhập tham số wms" />
                        </div>
                    </div>
                    <div className="col-12" hidden={wms_external}>
                        <Autocomplete
                            id="selectDatasource"
                            blurOnSelect={true}
                            fullWidth={true}
                            disableClearable={true}
                            inputValue={table}
                            onInputChange={(event, newValue) => setTable(newValue)}
                            onChange={(event, newValue) => setTable(newValue.tableName)}
                            options={props.listDataSource}
                            getOptionLabel={(option) => option.tableName}
                            renderInput={(params) => <TextField {...params} label="Nhập bảng wms" variant="outlined" />}
                        />
                        <div className="form-group" hidden>
                            <label htmlFor="selectStyle">Style:</label>
                            <input type="text" className="form-control" id="selectStyle" value={style} onChange={event => setStyle(event.target.value)} placeholder="Nhập style" />
                        </div>
                    </div>
                    <div className="col-12 mt-3" style={{display: 'flex', justifyContent: 'end'}}>
                        <Button variant="contained" color="primary" onClick={props.jumpToLayerStyleTab}>
                            Style Layer
                        </Button>
                    </div>
                    <div className="col-12">
                        <br />
                        <p>Chú ý: Thay đổi nguồn dữ liệu sẽ xóa dữ liệu cũ của cài đặt hiển thị và cài đặt lọc</p>
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
                <div className="row p-0 m-0">
                    <div className='col-12 mt-3'>
                        <div className="form-group">
                            <TextField
                                fullWidth
                                value={geoTiff}
                                onChange={(event) => {
                                    setValueTiffFile2({...valueTiffFile_2, TiffName: event.target.value})
                                    setGeoTiff(event.target.value);
                                }}
                                variant="outlined"
                                size="small"
                                label="Tên tiff"
                                error={props.error}
                                disabled={isDisableGeoTiff}
                            />
                            {props.error && <span className="error">{props.error}</span>}
                        </div>
                        <div>
                            <input
                                id="tiff-file-input"
                                type="file"
                                onChange={(event) => {
                                    let arrFiles = Array.prototype.slice.call(event.target.files);
                                    arrFiles.length > 0 &&
                                        setValueTiffFile2({...valueTiffFile_2, file: arrFiles[0],TiffName: ''});
                                        setGeoTiff('');
                                }}
                                accept=".tif, .tiff"
                            />
                        </div>
                    </div>
                    {
                        geoTiff && 
                        <div className="col-12 text-right">
                            <Button variant="contained" className='mr-3' onClick={() => {clearTiffFileInfo()}}>Xóa file Tiff</Button>
                        </div>
                    }
                    
                    <div className="col-12">
                        <br />
                        <p>Chú ý: Nên <span onClick={props.jumpToLayerStyleTab} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>thay đổi style của layer</span> sau khi xóa file Tiff</p>
                    </div>
                </div>
            </TabPanel>

            <RedirectToStyleModal
                open={openRedirectToStyle}
                handleClose={() => {
                    setOpenRedirectToStyle(false);
                    props.jumpToLayerStyleTab();
                }}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    listDataSource: state.initMap.arrayDataSource
})

const mapDispatchToProps = dispatch => bindActionCreators({
    GetListDataSource: InitMapStore.GetListDataSource,
    showLoading: appActions.ShowLoading,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLayerDataSource)