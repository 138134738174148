import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

// CreateStyleWithXmlStr: "/api/admin/Utils/create-sld-by-xml-string",
// ValidateStyle: "/api/admin/Utils/validate-style",
// UpdateStyle: "admin/Layer/UpdateStyle",

export const GetStyleNameByLayerName = (layerName) => {
  return service
    .get(`${ApiUrl.GetStyleNameByLayerName}?layerName=${layerName}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetStyleXmlByStyleName = (name) => {
  return service
    .get(`${ApiUrl.GetStyleXmlByStyleName}?styleName=${name}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const ValidateStyle = (styleXml) => {
  const formData = new FormData();
  formData.append("styleXml", styleXml);
  return service
    .post(`${ApiUrl.ValidateStyle}`, formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetListStyle = () => {
  return service
    .get(`${ApiUrl.GetListStyle}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const UpdateStyleForLayer = (styleName, layerName) => {
  return service
    .post(`${ApiUrl.UpdateStyleForLayer}?styleName=${styleName}&layerName=${layerName}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const CreateStyleWithXmlStr = (styleName, styleXml) => {
  const formData = new FormData();
  formData.append("RootFolderName", "Style");
  formData.append("StyleXml", styleXml);
  formData.append("StyleName", styleName);
  return service
    .post(`${ApiUrl.CreateStyleWithXmlStr}`, formData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};